import React from 'react'

const ArrowBroneIcon = () => {
  return (
		<svg width='31' height='8' viewBox='0 0 31 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M30.8536 4.35355C31.0488 4.15829 31.0488 3.84171 30.8536 3.64645L27.6716 0.464466C27.4763 0.269204 27.1597 0.269204 26.9645 0.464466C26.7692 0.659728 26.7692 0.976311 26.9645 1.17157L29.7929 4L26.9645 6.82843C26.7692 7.02369 26.7692 7.34027 26.9645 7.53553C27.1597 7.7308 27.4763 7.7308 27.6716 7.53553L30.8536 4.35355ZM0.5 3.5C0.223858 3.5 0 3.72386 0 4C0 4.27614 0.223858 4.5 0.5 4.5V3.5ZM30.5 3.5L0.5 3.5V4.5L30.5 4.5V3.5Z'
				fill='#C4C8CC'
			/>
		</svg>
  );
}

export default ArrowBroneIcon