import React from 'react';
import { useTranslation } from 'react-i18next';

const PrestigeBanner = ({ darkmode }) => {
	const { t } = useTranslation();
	return (
		<div className='bg-white dark:bg-[#272829] py-[13px]   flex-col flex  justify-center rounded-[5px]  px-[10px]  gap-3'>
			<div className='flex   gap-[20px] items-center'>
				{darkmode ? <img width={106} src='/images/prestige_white.png' alt='' /> : <img width={106} src='/images/prestige_black.png' alt='' />}
				<p className='text-[#000000] w-[220px] dark:text-white  text-sm font-normal'>{t('home.prestige_banner')}</p>
			</div>
		</div>
	);
};

export default PrestigeBanner;
