import React from 'react';

const SubtractIcon = () => {
	return (
		<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M10.0002 0L2.53024 1.65998C2.1143 1.75242 1.81836 2.12134 1.81836 2.54743V11.6263C1.81836 13.45 2.72982 15.1531 4.24727 16.1647L10.0002 20L15.7531 16.1647C17.2705 15.1531 18.182 13.45 18.182 11.6263V2.54743C18.182 2.12134 17.8861 1.75242 17.4701 1.65998L10.0002 0Z'
				fill='#00B849'
			/>
		</svg>
	);
};

export default SubtractIcon;
