import React, { useEffect, useState } from 'react';
import Axios from '../../utils/httpsClinet';
import RSelect from '../ui/RSelect';
import GenderSelector from './GenderSelector';
import InputUi from './inputUi'; // Import to'g'ri nom bilan

const HotelTourBookingForm = ({ people, onSubmitt, setModal = false, formsData }) => {
	const [formData, setFormData] = useState({});
	const [errors, setErrors] = useState({});
	const [selectedGender, setSelectedGender] = useState(null);
	const [country_list, setCountryList] = useState([]);

	useEffect(() => {
		if (people?.key) {
			const key = people?.key;
			setFormData({
				firstName: formsData?.[key]?.firstName || '',
				lastName: formsData?.[key]?.lastName || '',
				birthDate: formsData?.[key]?.birthDate || '',
				citizenship: formsData?.[key]?.citizenship || '',
				docType: formsData?.[key]?.docType || 'Заграничный паспорт',
				seria: formsData?.[key]?.seria || '',
				documentNumber: formsData?.[key]?.documentNumber || '',
				docIssued: formsData?.[key]?.docIssued || '',
				validUntil: formsData?.[key]?.validUntil || '',
				age: formsData?.[key]?.validUntil || '',
				human: formsData?.[key]?.human || '',
			});

			setSelectedGender(formsData?.[key]?.gender || null);
		}
	}, [people]);

	useEffect(() => {
		getCountryList();
	}, []);

	const getCountryList = () => {
		Axios()
			.get('/api/references/country-list')
			.then(res => {
				let newD = [];
				res?.data?.data?.forEach(el => {
					newD = [...newD, { value: el?.id, label: el?.name }];
				});
				setCountryList(newD);
			})
			.catch(err => {
				console.log(err);
			});
	};

	const handleSubmit = e => {
		e.preventDefault();
		let tt = true,
			validationErrors = {};
		if (!formData?.firstName) {
			tt = false;
			validationErrors = { ...validationErrors, firstName: true };
		}
		if (selectedGender === null) {
			tt = false;
			validationErrors = { ...validationErrors, gender: true };
		}
		if (!formData?.lastName) {
			tt = false;
			validationErrors = { ...validationErrors, lastName: true };
		}
		if (!formData?.birthDate) {
			tt = false;
			validationErrors = { ...validationErrors, birthDate: true };
		}
		if (!formData?.citizenship) {
			tt = false;
			validationErrors = { ...validationErrors, citizenship: true };
		}
		// if (!formData?.human) {
		// 	tt = false;
		// 	validationErrors = { ...validationErrors, human: true };
		// }
		// if (!formData?.docType) {
		// 	tt = false;
		// 	validationErrors = { ...validationErrors, docType: true };
		// }

		if (!formData?.seria) {
			tt = false;
			validationErrors = { ...validationErrors, seria: true };
		}
		if (!formData?.documentNumber) {
			tt = false;
			validationErrors = { ...validationErrors, documentNumber: true };
		}
		if (!formData?.docIssued) {
			tt = false;
			validationErrors = { ...validationErrors, docIssued: true };
		}
		if (!formData?.validUntil) {
			tt = false;
			validationErrors = { ...validationErrors, validUntil: true };
		}

		if (tt) {
			onSubmitt(people?.key, {
				firstName: formData?.firstName,
				lastName: formData?.lastName,
				birthDate: formData?.birthDate,
				citizenship: formData?.citizenship,
				docType: 'Заграничный паспорт',
				seria: formData?.seria,
				documentNumber: formData?.documentNumber,
				docIssued: formData?.docIssued,
				validUntil: formData?.validUntil,
				gender: selectedGender,
				human: formData?.human,
			});
			setModal(false);
		} else {
			setErrors(validationErrors);
		}
	};

	const handleChange = e => {
		const { name, value } = e?.target;
		// setFormData(prev => ({
		// 	...prev,
		// 	[name]: value,
		// }));
		// setErrors(prev => ({
		// 	...prev,
		// 	[name]: false,
		// }));

		if (name === 'seria') {
			const uppercaseValue = value.toUpperCase();
			if (/^[A-Z]{0,2}$/.test(uppercaseValue)) {
				setFormData(prev => ({
					...prev,
					[name]: uppercaseValue,
				}));
				setErrors(prev => ({
					...prev,
					[name]: false,
				}));
			}
		} else if (name === 'documentNumber') {
			const cleanedValue = value.replace(/\D/g, '');
			if (cleanedValue.length <= 7) {
				setFormData(prev => ({
					...prev,
					[name]: cleanedValue,
				}));
				setErrors(prev => ({
					...prev,
					[name]: false,
				}));
			}
		} else if (name === 'validUntil') {
			const validDatePattern = /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.(\d{4})$/;
			const dvalue = new Date(value);
			if (dvalue.getFullYear() < 9999) {
				setFormData(prev => ({
					...prev,
					[name]: value,
				}));
				setErrors(prev => ({
					...prev,
					[name]: false,
				}));
			}
		} else if (name === 'docIssued') {
			const validDatePattern = /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.(\d{4})$/;
			const dvalue = new Date(value);
			if (dvalue.getFullYear() < 9999) {
				setFormData(prev => ({
					...prev,
					[name]: value,
				}));
				setErrors(prev => ({
					...prev,
					[name]: false,
				}));
			}
		} else {
			setFormData(prev => ({
				...prev,
				[name]: value,
			}));
			setErrors(prev => ({
				...prev,
				[name]: false,
			}));
		}
	};
	const handleChangeDate = (e, name) => {
		setFormData(prev => ({
			...prev,
			[name]: e,
		}));
		setErrors(prev => ({
			...prev,
			[name]: false,
		}));
	};
	const handleSelectChange = e => {
		setFormData(prev => ({
			...prev,
			citizenship: e,
		}));
		setErrors(prev => ({
			...prev,
			citizenship: false,
		}));
	};

	const handleSelectChangeHuman = e => {
		setFormData(prev => ({
			...prev,
			human: e.target.value,
		}));
		setErrors(prev => ({
			...prev,
			human: false,
			gender: false,
		}));

		if (e.target.value === 'MR') {
			setSelectedGender('male');
		} else if (e.target.value === 'MRS') {
			setSelectedGender('female');
		} else if (e.target.value === 'CHD') {
			setSelectedGender('male');
		} else {
			setSelectedGender(null);
		}
	};
	const mr_options = [
		{ value: 'MR', label: 'Mr - Господин' },
		{ value: 'MRS', label: 'Mrs - Госпожа' },
		{ value: 'CHD', label: 'CHD - Ребенок до 16' },
	];

	return (
		<form onSubmit={handleSubmit}>
			<div className='dark:bg-[#141414]  rounded-xl w-full'>
				<div className='flex flex-col justify-between  w-full '>
					<div className='flex flex-col gap-4'>
						{/* <InputUi
						value={formData.human}
						err={errors}
						onChange={handleSelectChangeHuman}
						name='human'
						label=''
						placeholder='Введите фамилию на Латинице'
						type='select'
						options={mr_options}
					/> */}
						<GenderSelector err={errors} setErrors={setErrors} selectedGender={selectedGender} setSelectedGender={setSelectedGender} />
						<InputUi is_label={false} value={formData.lastName} err={errors} onChange={handleChange} name='lastName' placeholder='Введите фамилию на Латинице' />
						<InputUi value={formData.firstName} err={errors} onChange={handleChange} name='firstName' is_label={false} placeholder='Введите имя на Латинице' />
						<div className='flex gap-[10px]'>
							{/* <BasicDatePicker value={formData.birthDate || null} error={errors} name='birthDate' onChange={v => handleChangeDate(v, 'birthDate')} /> */}
							<InputUi id={'date1'} value={formData.birthDate} err={errors} onChange={handleChange} name='birthDate' is_label={false} type='date' w='w-full' />

							<RSelect
								value={formData.citizenship}
								err={errors}
								onChange={handleSelectChange}
								name='citizenship'
								is_label={false}
								placeholder={'Выберите'}
								options={country_list}
							/>
						</div>
						<InputUi value={formData.docType} err={errors} onChange={() => {}} name='docType' is_label={false} disabled={true} placeholder='Заграничный паспорт' />
						<div className='flex gap-[10px]'>
							<InputUi value={formData.seria} err={errors} onChange={handleChange} name='seria' w='w-1/4' is_label={false} placeholder='AA' />
							<InputUi value={formData.documentNumber} err={errors} onChange={handleChange} name='documentNumber' w='w-3/4' is_label={false} placeholder='1234567' />
						</div>
						<div className='flex gap-[10px]'>
							{/* <BasicDatePicker value={formData.docIssued || null} error={errors} name='docIssued' onChange={v => handleChangeDate(v, 'docIssued')} />
							<BasicDatePicker value={formData.validUntil || null} error={errors} name='validUntil' onChange={v => handleChangeDate(v, 'validUntil')} /> */}

							<InputUi id={'date2'} value={formData.docIssued} err={errors} onChange={handleChange} name='docIssued' is_label={false} type='date' w='w-full' />
							<InputUi id={'date3'} value={formData.validUntil} err={errors} onChange={handleChange} name='validUntil' is_label={false} type='date' w='w-full' />
						</div>
						<div className='bg-[#BBD3FF] rounded-[10px] p-[15px] text-sm flex gap-3 items-center'>
							<div className='min-w-[25px] min-h-[25px]'>
								<svg width='29' height='25' viewBox='0 0 29 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
									<path
										fillRule='evenodd'
										clipRule='evenodd'
										d='M15.3114 17.2354H13.613L13.6017 15.6729H15.3114V17.2354ZM13.6017 13.9302H15.3V7.76458H13.6017V13.9302ZM14.4565 2.34375C8.36956 2.34375 3.41711 6.9 3.41711 12.5C3.41711 18.1 8.36956 22.6562 14.4565 22.6562C20.5435 22.6562 25.4959 18.1 25.4959 12.5C25.4959 6.9 20.5435 2.34375 14.4565 2.34375Z'
										fill='#235DFF'
									/>
								</svg>
							</div>
							<p> Срок истечения документа должен быть не менее 6 месяцев после окончания тура</p>{' '}
						</div>
					</div>
				</div>
			</div>
			<div className=' dark:bg-[#272829] bg-white z-30  w-full  absolute bottom-0 left-0 right-0'>
				<div className='!container py-1'>
					<button
						className={`mb-[10px] w-full  h-[50px] flex items-center justify-center gap-2 md:font-semibold font-medium md:text-base text-sm md:px-4 px-1.5 py-3 rounded-xl duration-150 cursor-pointer mt-4 border border-blueRibbon bg-blueRibbon text-white`}
						type='submit'
					>
						Сохранить данные
					</button>
				</div>
			</div>
		</form>
	);
};

export default HotelTourBookingForm;
