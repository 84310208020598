import { motion } from 'framer-motion';
import React from 'react';
import OverflowHidden from '../OverflowHidden';
import ButtonMain from '../ui/ButtonMain';

const ModalBottom = ({ close, modal, btnShow = true, textBtn, heightModal, content, clickFunction }) => {
	return (
		<>
			<div className={`container_main relative z-9999 duration-300 ${modal ? 'opacity-100' : 'opacity-0'}`}>
				<div className={`fixed top-0 left-0 flex justify-center z-[9090] ${modal ? 'w-full h-full' : 'w-0 h-0'}`}>
					<div className='container_main w-full backdrop-blur-sm' style={{ background: '#09101D99' }} onClick={close} />
				</div>

				<motion.div
					className='fixed z-[9091] bottom-0 left-0 flex justify-center w-full'
					initial={{ y: '100%' }}
					animate={{ y: modal ? 0 : '100%' }}
					transition={{ type: 'spring', stiffness: 400, damping: 35 }}
					style={{ overflow: 'hidden' }}
				>
					<div className={`container_main flex w-full bg-[#EBF0F5] dark:bg-[#141414] rounded-tr-[10px] rounded-tl-[10px] duration-300 ${modal ? heightModal : 'h-[0]'}`}>
						<div className='flex flex-col justify-between w-full gap-3'>
							<div className={`flex flex-col ${btnShow ? 'h-[calc(100%_-_70px)]' : 'h-full'} `}>
								<div className='flex justify-center w-full py-[10px] px-[16px]'>
									<div className='h-[6px] w-[50px] rounded-[10px] bg-[#235DFF]' />
								</div>
								<div className='flex h-full overflow-y-auto'>{content}</div>
							</div>
							{btnShow && <ButtonMain type='button' type_color='t_blue' className='w-full mb-[20px]' text={textBtn} onClick={() => clickFunction()} />}
						</div>
					</div>
				</motion.div>
			</div>
			{modal && <OverflowHidden />}
		</>
	);
};

export default ModalBottom;
