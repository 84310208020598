import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import HotelTourBookingForm from '../../components/form/HotelTourBookingForm';

const BroneForm = ({ darkmode, setModal }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { formsData, selectedPerson } = useSelector(s => s);

	const handleFormsDataSubmit = (key, formData) => {
		dispatch({
			type: 'SET_FORMSDATA',
			payload: {
				...formsData,
				[key]: formData,
			},
		});
	};

	return (
		<div className=' dark:bg-[#141414] bg-[#EBF0F5] w-full '>
			<HotelTourBookingForm formsData={formsData || {}} people={selectedPerson || []} onSubmitt={handleFormsDataSubmit} setModal={setModal} />
		</div>
	);
};
export default BroneForm;
